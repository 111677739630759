import { createContext } from "react";

interface UIContextInterface {
  isSourceModalFlowOpen: boolean;
  setSourceModalFlowOpen: (value: boolean) => void;
  dataViewNodeId: string;
  setDataViewNodeId: (value: string) => void;
}

const UIContext = createContext<UIContextInterface | null>(null);

export default UIContext;
