import { RouteObject } from "react-router-dom";

import { lazyWithReload } from "@/core/lazyWithRetry";
import { ROUTES, ROUTE_FEATURE_FLAGS } from "@/routes/constants";
import { ProtectedRoute } from "@components/Global/ProtectedRoute";

const MatchingView = lazyWithReload(() => import("./MatchingGateway"));

export const matchingRoutes: RouteObject = {
  element: (
    <ProtectedRoute
      featureFlag={ROUTE_FEATURE_FLAGS[ROUTES.MATCHING]}
      redirectPath={ROUTES.MATCHING}
    />
  ),
  children: [{ path: ROUTES.MATCHING, element: <MatchingView /> }],
};
