import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactNode } from "react";

import { queryClient } from "./queryClient";

// Get the current mode from Vite's env variable
const isDevelopment = process.env.NODE_ENV !== "production";

interface QueryProviderProps {
  children: ReactNode;
}

/**
 * React Query provider component that wraps the application
 * and provides the query client to all child components.
 * Also includes React Query DevTools in development mode.
 */
export const QueryProvider = ({ children }: QueryProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {isDevelopment && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
};
