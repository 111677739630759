import { RouteObject } from "react-router-dom";

import { lazyWithReload } from "@/core/lazyWithRetry";
import { ROUTES, ROUTE_FEATURE_FLAGS } from "@/routes/constants";
import { ProtectedRoute } from "@components/Global/ProtectedRoute";

const SettlementView = lazyWithReload(() => import("./SettlementView"));

export const settlementRoutes: RouteObject = {
  element: <ProtectedRoute featureFlag={ROUTE_FEATURE_FLAGS[ROUTES.SETTLEMENT]} />,
  children: [{ path: ROUTES.SETTLEMENT, element: <SettlementView /> }],
};
