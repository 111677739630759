import { Component } from "react";

import { errorLoggingAPI } from "../../core/errorLogging";

export class ErrorBoundary extends Component<{ children: React.ReactNode }, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    errorLoggingAPI("Whitescreen", error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                lineHeight: "20px",
              }}
            >
              Something went wrong. Please try again
            </p>
            <a href={window.location.href}>
              <button
                data-action="Retry [ErrorBoundary]"
                style={{
                  padding: "5px 12px",
                  borderRadius: "4px",
                  outline: "none",
                  border: "none",
                  color: "#FFF",
                  backgroundColor: "#1D42C9",
                  fontFamily: "ABCWhyte",
                  fontWeight: "700",
                  lineHeight: "22px",
                  fontSize: "12px",
                  maxWidth: "96px",
                  cursor: "pointer",
                }}
              >
                Tap to retry
              </button>
            </a>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
