import { useAuth0 } from "@auth0/auth0-react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";

import { AUTH0_ORG_CLAIM_URL } from "@/utils/auth/config";

interface LaunchDarklyProviderProps {
  children: React.ReactElement;
}

interface LDProviderProps {
  children: React.ReactNode;
}

const LaunchDarklyProvider: React.FC<LaunchDarklyProviderProps> = ({ children }) => {
  const [LDProvider, setLDProvider] = useState<React.ComponentType<LDProviderProps> | null>(null);
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    const initLD = async () => {
      if (isAuthenticated && user) {
        const clientSideID: string = import.meta.env.PROD
          ? (import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID_PROD as string)
          : (import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID_DEV as string);

        try {
          const ldProvider = await asyncWithLDProvider({
            clientSideID,
            context: {
              kind: "user",
              key: user.email,
              email: user.email,
              org_name: user[AUTH0_ORG_CLAIM_URL] as string,
            },
            options: {
              bootstrap: "localStorage",
            },
          });
          setLDProvider(() => ldProvider);
        } catch (error) {
          console.error("Failed to initialize LaunchDarkly:", error);
        }
      }
    };

    void initLD();
  }, [isAuthenticated, user]);

  if (LDProvider) {
    return <LDProvider>{children}</LDProvider>;
  }
  return children;
};

export default LaunchDarklyProvider;
