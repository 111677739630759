import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ERROR, INVALID_REQUEST, INVITATION, ORGANIZATION } from "./utils/constants";

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const error = searchParams.get(ERROR);
    const invitation = searchParams.get(INVITATION);
    const organizationId = searchParams.get(ORGANIZATION);

    if (error === INVALID_REQUEST) {
      navigate("/no-organizations");
    } else if (invitation && organizationId) {
      loginWithRedirect({
        invitation: invitation,
        organization: organizationId,
      });
    } else {
      loginWithRedirect();
    }
  }, [navigate, loginWithRedirect, searchParams]);

  return <Box sx={{ width: "100%", height: "100%", backgroundColor: "#020A27" }}></Box>;
};

export default Login;
