import {
  CompositePropagator,
  W3CBaggagePropagator,
  W3CTraceContextPropagator,
} from "@opentelemetry/core";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { FetchInstrumentation } from "@opentelemetry/instrumentation-fetch";
import { UserInteractionInstrumentation } from "@opentelemetry/instrumentation-user-interaction";
import { browserDetector, Resource } from "@opentelemetry/resources";
import { detectResourcesSync } from "@opentelemetry/resources/build/src/detect-resources";
import { BatchSpanProcessor } from "@opentelemetry/sdk-trace-base";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";
import { Location, useLocation } from "react-router-dom";

import { SessionIdProcessor } from "./SessionIdProcessor";

const FrontendTracer = async () => {
  const location = useLocation();
  const uuidRegex = new RegExp("[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}");

  const { ZoneContextManager } = await import("@opentelemetry/context-zone");

  const _getUuid = (path: string): string => {
    const uuid = path.match(uuidRegex)[0];
    if (!uuid) return "No uuid";
    return uuid;
  };

  const _getCleanerPath = (path: string, split: string): string => {
    const removedUuidFromPath = path.replace(uuidRegex, ":uuid");
    const [_, cleanerPath] = removedUuidFromPath.split(split);

    return cleanerPath;
  };

  const _getApiVersion = (path: string): string => {
    const [_, version] = path.split("/api/");
    const [apiVersion] = version.split("/");
    return apiVersion;
  };

  const _getSearchParams = (location: Location): any => {
    const params = new URLSearchParams(location.search);

    const searchParams = {};

    for (const param of params) {
      const key = param[0];
      searchParams[`target.searchParam_${key}`] = param[1];
    }

    return searchParams;
  };

  const _getAction = (element: HTMLElement): string => {
    return element.dataset.action;
  };

  const _getFallbackAction = (element: HTMLElement): string => {
    return element.textContent || element.innerText;
  };

  const _getTraceName = (element: HTMLElement): string => {
    const action = _getAction(element);
    const fallbackAction = _getFallbackAction(element);
    const cleanerPath = _getCleanerPath(location.pathname, "/app");
    if (action) {
      return `${action}`;
    } else {
      return `${fallbackAction} at ${cleanerPath}`;
    }
  };

  let resource = new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: import.meta.env.VITE_OTEL_SERVICE_NAME,
  });

  const detectedResources = detectResourcesSync({ detectors: [browserDetector] });
  resource = resource.merge(detectedResources);
  const provider = new WebTracerProvider({
    resource,
  });

  provider.addSpanProcessor(new SessionIdProcessor());

  if (import.meta.env.PROD) {
    provider.addSpanProcessor(
      new BatchSpanProcessor(
        new OTLPTraceExporter({
          url: import.meta.env.VITE_OTEL_EXPORTER_OTLP_TRACES_ENDPOINT,
          headers: {
            "signoz-access-token": import.meta.env.VITE_OTEL_SIGNOZ_ACCESS_TOKEN,
          },
          keepAlive: true,
        })
      )
    );
  } else {
    // provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
  }

  const contextManager = new ZoneContextManager();

  provider.register({
    contextManager,
    propagator: new CompositePropagator({
      propagators: [new W3CBaggagePropagator(), new W3CTraceContextPropagator()],
    }),
  });

  registerInstrumentations({
    instrumentations: [
      new UserInteractionInstrumentation({
        eventNames: ["submit", "click", "keypress"],
        shouldPreventSpanCreation: (event, element, span) => {
          if (!element.dataset.action) return true;
          span.updateName(_getTraceName(element));
          const path = location.pathname;
          span.setAttribute("target.path", _getCleanerPath(path, "/app"));
          span.setAttribute("target.uuid", _getUuid(path));
          span.setAttribute("target.action", _getAction(element));
          span.setAttributes(_getSearchParams(location));

          return false;
        },
      }),
      new FetchInstrumentation({
        propagateTraceHeaderCorsUrls: /.*/,
        clearTimingResources: true,
        applyCustomAttributesOnSpan: (span, request, response) => {
          if (response instanceof Response && response.ok) {
            const cleanURL = _getCleanerPath(response.url, "/api");
            span.updateName(`${request.method} ${cleanURL}`);
            span.setAttribute("target.uuid", _getUuid(response.url));
            span.setAttribute("target.path", _getCleanerPath(response.url, "/api"));
            span.setAttribute("request.method", request.method);
            span.setAttribute("api.version", _getApiVersion(response.url));
          }
        },
      }),
    ],
  });
};

export default FrontendTracer;
