import { RouteObject } from "react-router-dom";

import { lazyWithReload } from "@/core/lazyWithRetry";
import { ROUTES, ROUTE_FEATURE_FLAGS } from "@/routes/constants";
import { ProtectedRoute } from "@components/Global/ProtectedRoute";

// Lazy load Agent components
const AgentsView = lazyWithReload(() => import("./components/AgentsView"));
const AgentSettingsView = lazyWithReload(() => import("./components/AgentSteps/AgentSettingsView"));
const PromptEditorView = lazyWithReload(() => import("./components/AgentSteps/PromptEditorView"));
const ReviewAndRunView = lazyWithReload(() =>
  import("./components/AgentSteps/ReviewAndRunView").then(module => ({
    default: module.ReviewAndRunView,
  }))
);
const AgentScheduleView = lazyWithReload(() =>
  import("./components/AgentScheduleView/AgentScheduleView").then(module => ({
    default: module.AgentScheduleView,
  }))
);

export const agentRoutes: RouteObject = {
  element: <ProtectedRoute featureFlag={ROUTE_FEATURE_FLAGS[ROUTES.AGENTS.ROOT]} />,
  children: [
    { path: ROUTES.AGENTS.ROOT, element: <AgentsView /> },
    { path: ROUTES.AGENTS.SETTINGS(), element: <AgentSettingsView /> },
    { path: ROUTES.AGENTS.CHAT(), element: <PromptEditorView /> },
    { path: ROUTES.AGENTS.REVIEW(), element: <ReviewAndRunView /> },
    { path: ROUTES.AGENTS.SCHEDULE(), element: <AgentScheduleView /> },
  ],
};
