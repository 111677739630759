/* eslint-disable no-console */
import { useAuth0 } from "@auth0/auth0-react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { ReactNode, useEffect, useMemo, useState } from "react";

import { accessTokenRequest } from "@/core/auth";

import config from "../core/config";

import { SignalRContext } from "./SignalRContext";

interface SignalRProviderProps {
  children: ReactNode;
}

// Provider component that manages the SignalR connection
export const SignalRProvider = ({ children }: SignalRProviderProps) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [connected, setConnected] = useState<boolean>(false);

  const logSignalR = (message: string, error: unknown = null) => {
    if (import.meta.env.PROD) return;
    console.log(`[SignalR message] ${message}`);
    if (error) {
      console.error(`[SignalR error] ${JSON.stringify(error)}`);
    }
  };

  useEffect(() => {
    let isMounted = true;
    let retryCount = 0;
    const maxRetries = 3;
    const retryDelay = 500; // ms

    const connectToSignalR = async () => {
      try {
        const newConnection = new HubConnectionBuilder()
          .withUrl(config.API_SIGNALR_URL, {
            accessTokenFactory: async () => {
              try {
                const token = await getAccessTokenSilently(accessTokenRequest);
                if (!token) {
                  throw new Error("No token received for SignalR connection");
                }
                return token;
              } catch (error) {
                logSignalR("Error getting access token for SignalR", error);
                throw error;
              }
            },
          })
          .withAutomaticReconnect()
          .build();

        if (!isMounted) return;

        setConnection(newConnection);

        newConnection.onreconnecting(error => {
          logSignalR("Reconnecting...", error);
          setConnected(false);
        });

        newConnection.onreconnected(() => {
          logSignalR("Reconnected.");
          setConnected(true);
        });

        newConnection.onclose(error => {
          logSignalR("Connection closed.", error);
          setConnected(false);
        });

        try {
          logSignalR("Connecting...");
          await newConnection.start();
          logSignalR("Connected.");
          if (isMounted) {
            setConnected(true);
          }
        } catch (err: unknown) {
          logSignalR("Error connecting to SignalR.", err);

          // Retry logic
          if (retryCount < maxRetries && isMounted) {
            retryCount++;
            logSignalR(`Retrying SignalR connection (${retryCount}/${maxRetries})...`);
            setTimeout(connectToSignalR, retryDelay);
          }
        }
      } catch (error) {
        logSignalR("Error setting up SignalR connection", error);

        // Retry logic
        if (retryCount < maxRetries && isMounted) {
          retryCount++;
          logSignalR(`Retrying SignalR connection setup (${retryCount}/${maxRetries})...`);
          setTimeout(connectToSignalR, retryDelay);
        }
      }
    };

    if (isAuthenticated) {
      void connectToSignalR();
    } else if (!isAuthenticated && connection) {
      void connection.stop();
      setConnected(false);
      setConnection(null);
    }

    // Clean up the connection on component unmount
    return () => {
      isMounted = false;
      if (connection) {
        void connection.stop();
      }
    };
    // Adding connection to the dependency array will cause the connection to be recreated on every render which infinite loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, getAccessTokenSilently]);

  const contextValue = useMemo(
    () => ({
      connection,
      connected,
    }),
    [connection, connected]
  );

  return <SignalRContext.Provider value={contextValue}>{children}</SignalRContext.Provider>;
};
