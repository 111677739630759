/**
 * Application route constants
 * This file centralizes all route path definitions to avoid duplication and make route management easier
 */

// Base paths
export const ROUTES = {
  // Root paths
  ROOT: "/",
  LOGIN: "/login",
  APP: "/app",
  NO_ORGANIZATIONS: "/no-organizations",

  // Feature paths
  AGENTS: {
    ROOT: "/app/agents",
    SETTINGS: (agentUuid: string = ":agentUuid") => `/app/agents/${agentUuid}/settings`,
    CHAT: (agentUuid: string = ":agentUuid") => `/app/agents/${agentUuid}/chat`,
    REVIEW: (agentUuid: string = ":agentUuid") => `/app/agents/${agentUuid}/review`,
    SCHEDULE: (agentUuid: string = ":agentUuid") => `/app/agents/${agentUuid}/schedule`,
  },

  TASKS: "/app/tasks",

  PROJECTS: {
    ROOT: "/app/projects",
    UNASSIGNED_DASHBOARD: "/app/projects/unassigned/dashboard",
    UNASSIGNED_ESTIMATION: "/app/projects/unassigned/estimation",
    DETAIL: (projectUuid: string = ":projectUuid") => `/app/projects/${projectUuid}`,
    TYPE: (projectUuid: string = ":projectUuid", type: string = ":type") =>
      `/app/projects/${projectUuid}/${type}`,
  },

  INTEGRATIONS: {
    ROOT: "/app/integrations",
    REDIRECT: "/app/integrations/redirect",
    NEW: (type: string = ":type") => `/app/integrations/${type}/new`,
    DETAIL: (type: string = ":type", id: string = ":id") => `/app/integrations/${type}/${id}`,
  },

  SETTINGS: "/app/settings",
  MATCHING: "/app/matching",
  UPLOAD: "/app/upload",
  DASHBOARD: "/app/dashboard",
  SETTLEMENT: "/app/settlement",
  MAINTENANCE: "/app/maintenance",
};

/**
 * Feature flags associated with routes
 * Maps route paths to their corresponding feature flags
 */
export const ROUTE_FEATURE_FLAGS = {
  [ROUTES.AGENTS.ROOT]: "gen3865WebAgent",
  [ROUTES.TASKS]: "gen1460Workflows",
  [ROUTES.PROJECTS.ROOT]: "feeEstimation",
  [ROUTES.MATCHING]: "matchingTool",
  [ROUTES.UPLOAD]: "fileUpload",
  [ROUTES.DASHBOARD]: "lookerDashboard",
  [ROUTES.SETTLEMENT]: "settlements",
};
