import { User } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";

import { AUTH0_ORG_CLAIM_URL } from "@/utils/auth/config";

import FrontendTracer from "../utils/telemetry/FrontendTracer";

import { UserData } from "./analytics";

/**
 * Initialize the OpenTelemetry frontend tracer
 * Will only initialize if window is defined (client-side)
 */
export const initializeFrontendTracer = (): void => {
  if (typeof window !== "undefined") {
    FrontendTracer();
  }
};

/**
 * Configure Sentry scope with user information for error tracking
 * @param userData - User data for identification
 * @param isAuthenticated - Whether the user is authenticated
 */
export const configureSentryScope = (
  userData: User | UserData | null,
  isAuthenticated: boolean
): void => {
  if (!isAuthenticated || !userData) return;

  Sentry.configureScope(function (scope) {
    scope.setTag("schema", userData[AUTH0_ORG_CLAIM_URL]);
    scope.setUser({
      email: userData.email,
    });
  });
};
