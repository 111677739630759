import { QueryClient } from "@tanstack/react-query";

/**
 * Configure and create QueryClient with default options.
 * This provides a centralized configuration for all React Query hooks.
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error: any) => {
        if (error?.status === 401 && failureCount < 3) {
          console.log(`Auth error detected, retrying request (attempt ${failureCount + 1}/3)...`);
          return true;
        }
        return false;
      },
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 10000),
    },
    mutations: {
      retry: (failureCount, error: any) => {
        if (error?.status === 401 && failureCount < 3) {
          console.log(
            `Auth error detected in mutation, retrying (attempt ${failureCount + 1}/3)...`
          );
          return true;
        }
        return false;
      },
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 10000),
    },
  },
});
