import { RouteObject } from "react-router-dom";

import { lazyWithReload } from "@/core/lazyWithRetry";
import { ROUTES, ROUTE_FEATURE_FLAGS } from "@/routes/constants";
import { ProtectedRoute } from "@components/Global/ProtectedRoute";

const UploadView = lazyWithReload(() => import("./Upload"));

export const uploadRoutes: RouteObject = {
  element: <ProtectedRoute featureFlag={ROUTE_FEATURE_FLAGS[ROUTES.UPLOAD]} />,
  children: [{ path: ROUTES.UPLOAD, element: <UploadView /> }],
};
