import { useAuth0 } from "@auth0/auth0-react";
import { Box, LinearProgress } from "@mui/material";
import { Suspense } from "react";
import { Navigate, Outlet, RouteObject, useRoutes } from "react-router-dom";

import AppContainer from "@/containers/AppContainer";
import { agentRoutes } from "@/features/Agents/routes";
import Login from "@/features/Auth/Login";
import NoOrganizations from "@/features/Auth/NoOrganizations";
import { integrationRoutes } from "@/features/Integrations/routes";
import { lookerRoutes } from "@/features/Looker/routes";
import Maintenance from "@/features/Maintenance/Maintenance";
import { matchingRoutes } from "@/features/Matching/routes";
import { projectRoutes } from "@/features/Projects/routes";
import { settingsRoutes } from "@/features/Settings/routes";
import { settlementRoutes } from "@/features/Settlements/routes";
import { uploadRoutes } from "@/features/Upload/routes";
import { workflowRoutes } from "@/features/Workflow/routes";

import { ROUTES } from "./constants";

const RouteLoadingIndicator = () => (
  <Box sx={{ width: "100%" }}>
    <LinearProgress color="primary" />
  </Box>
);

const AuthenticatedLayout = () => (
  <AppContainer>
    <Suspense fallback={<RouteLoadingIndicator />}>
      <Outlet />
    </Suspense>
  </AppContainer>
);

const protectedAppRoutes: RouteObject[] = [
  { path: ROUTES.MAINTENANCE, element: <Maintenance /> },
  // Feature Routes
  agentRoutes,
  workflowRoutes,
  projectRoutes,
  ...integrationRoutes,
  settingsRoutes,
  matchingRoutes,
  uploadRoutes,
  ...lookerRoutes,
  settlementRoutes,

  // Default redirects
  { path: "", element: <Navigate to={ROUTES.MATCHING} replace /> },
  { path: "*", element: <Navigate to={ROUTES.MATCHING} replace /> },
];

// Routes accessible when not authenticated
const publicRoutes: RouteObject[] = [
  { path: ROUTES.LOGIN, element: <Login /> },
  { path: ROUTES.ROOT, element: <Navigate to={ROUTES.LOGIN} replace /> },
  { path: ROUTES.NO_ORGANIZATIONS, element: <NoOrganizations /> },
];

// --- AppRoutes Component ---

export const AppRoutes = () => {
  const { isLoading, isAuthenticated } = useAuth0();

  const routes: RouteObject[] = [
    {
      path: ROUTES.APP,
      element: isAuthenticated ? <AuthenticatedLayout /> : <Navigate to={ROUTES.LOGIN} replace />,
      children: protectedAppRoutes,
    },
    {
      path: ROUTES.ROOT,
      element: isAuthenticated ? <Navigate to={ROUTES.APP} replace /> : <Outlet />,
      children: publicRoutes,
    },
    // Catch-all for undefined top-level paths
    { path: "*", element: <Navigate to={ROUTES.ROOT} replace /> },
  ];

  const element = useRoutes(routes);

  if (isLoading) {
    return <LinearProgress color="primary" />;
  }

  return element;
};

export {};
