import { useAuth0 } from "@auth0/auth0-react";
import { LinearProgress } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { default as MixPanel } from "mixpanel-browser";
import "handsontable/dist/handsontable.full.css";
import { Suspense, useEffect, useState } from "react";

import "@/features/Agents/components/AgentScheduleView/AgentScheduleView.css";
import AuthInterceptor from "@components/Global/AuthInterceptor";

import LaunchDarklyProvider from "./context/LaunchDarklyProvider";
import MaintenanceProvider from "./context/MaintenanceProvider";
import MixpanelContext from "./context/MixpanelContext";
import { SignalRProvider } from "./context/SignalRProvider";
import UIContext from "./context/UIContext";
import { AppRoutes } from "./routes/config";
import {
  identifyHotjarUser,
  initializeHotjar,
  initializeMixpanel,
  setMixpanelIdentity,
} from "./services/analytics";
import { configureSentryScope, initializeFrontendTracer } from "./services/telemetry";
import { theme } from "./themeSettings";

LicenseInfo.setLicenseKey(
  "6c57842bc8121b8b75b812c921282df4Tz0xMTA2MzcsRT0xNzc1MDg3OTk5MDAwLFM9cHJvLExNPXBlcnBldHVhbCxQVj0yMDIyLEtWPTI="
);

function App() {
  const { isAuthenticated, user } = useAuth0();
  const [isSourceModalFlowOpen, setSourceModalFlowOpen] = useState(false);
  const [dataViewNodeId, setDataViewNodeId] = useState(null);

  const [mixpanel] = useState(MixPanel);

  // Initialize Hotjar and identify user if authenticated
  useEffect(() => {
    initializeHotjar();
    if (isAuthenticated && user) {
      identifyHotjarUser(user);
    }
  }, [isAuthenticated, user]);

  // Initialize Mixpanel when authentication status changes
  useEffect(() => {
    initializeMixpanel(mixpanel, isAuthenticated);
    if (isAuthenticated && user) {
      setMixpanelIdentity(mixpanel, user);
    }
  }, [isAuthenticated, user, mixpanel]);

  // Initialize frontend tracer
  useEffect(() => {
    initializeFrontendTracer();
  }, []);

  // Configure Sentry scope with user information
  useEffect(() => {
    configureSentryScope(user, isAuthenticated);
  }, [isAuthenticated, user]);

  return (
    <ThemeProvider theme={theme}>
      <AuthInterceptor>
        <SignalRProvider>
          <LaunchDarklyProvider>
            <MaintenanceProvider>
              <MixpanelContext.Provider value={{ mixpanel }}>
                <UIContext.Provider
                  value={{
                    isSourceModalFlowOpen,
                    setSourceModalFlowOpen,
                    dataViewNodeId,
                    setDataViewNodeId,
                  }}
                >
                  <Suspense fallback={<LinearProgress color="primary" />}>
                    <AppRoutes />
                  </Suspense>
                </UIContext.Provider>
              </MixpanelContext.Provider>
            </MaintenanceProvider>
          </LaunchDarklyProvider>
        </SignalRProvider>
      </AuthInterceptor>
    </ThemeProvider>
  );
}

export default App;
