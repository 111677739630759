import { useRef, useState } from "react";

interface UseSidebarReturn {
  hovered: boolean;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
}

/**
 * Custom hook for managing Sidebar hover state
 */
export const useSidebar = (): UseSidebarReturn => {
  const [hovered, setHovered] = useState(false);
  const collapseTimerRef = useRef<number | null>(null);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
    collapseTimerRef.current = null;
  };

  return {
    hovered,
    handleMouseEnter,
    handleMouseLeave,
  };
};
