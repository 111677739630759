import { Navigate, RouteObject } from "react-router-dom";

import { lazyWithReload } from "@/core/lazyWithRetry";
import { ROUTE_FEATURE_FLAGS, ROUTES } from "@/routes/constants";
import { ProtectedRoute } from "@components/Global/ProtectedRoute";

const ProjectsGateway = lazyWithReload(() => import("./ProjectsGateway"));
const ProjectsViewItem = lazyWithReload(() => import("./ProjectsViewItem"));

export const projectRoutes: RouteObject = {
  element: <ProtectedRoute featureFlag={ROUTE_FEATURE_FLAGS[ROUTES.PROJECTS.ROOT]} />,
  children: [
    { path: ROUTES.PROJECTS.ROOT, element: <ProjectsGateway /> },
    {
      path: ROUTES.PROJECTS.UNASSIGNED_DASHBOARD,
      element: <Navigate to={ROUTES.PROJECTS.UNASSIGNED_ESTIMATION} />,
    },
    { path: ROUTES.PROJECTS.DETAIL(), element: <ProjectsViewItem /> },
    { path: ROUTES.PROJECTS.TYPE(), element: <ProjectsViewItem /> }, // Reuses ProjectsViewItem for type-specific view
  ],
};
