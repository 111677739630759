import { RouteObject } from "react-router-dom";

import { lazyWithReload } from "@/core/lazyWithRetry";
import { ROUTES, ROUTE_FEATURE_FLAGS } from "@/routes/constants";
import { ProtectedRoute } from "@components/Global/ProtectedRoute";

const WorkflowView = lazyWithReload(() => import("./WorkflowView"));

export const workflowRoutes: RouteObject = {
  element: <ProtectedRoute featureFlag={ROUTE_FEATURE_FLAGS[ROUTES.TASKS]} />,
  children: [{ path: ROUTES.TASKS, element: <WorkflowView /> }],
};
