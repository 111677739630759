import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Box, styled, Typography } from "@mui/material";
import React from "react";

import { capitalize } from "@/core/utils";
import { AUTH0_ORG_CLAIM_URL } from "@/utils/auth/config";

import { SIDEBAR_GROUPS } from "../api/navItems";
import { SidebarProps } from "../types";

import { NavItem } from "./NavItem";

const SidebarContainer = styled(Box, {
  shouldForwardProp: prop => prop !== "expanded",
})<{ expanded?: boolean }>(({ theme, expanded }) => ({
  minWidth: expanded ? "200px" : "64px",
  width: expanded ? "200px" : "64px",
  backgroundColor: theme.palette.background.paper,
  height: "100vh",
  maxHeight: "100%",
  display: "flex",
  flexDirection: "column",
  boxShadow: theme.shadows[2],
  transition: theme.transitions.create(["width", "min-width"], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.standard,
  }),
  overflow: "hidden",
  overflowX: "hidden",
  position: "fixed",
  zIndex: 1200,
  top: 0,
  left: 0,
  "&[aria-expanded='true']": {
    width: "224px",
  },
  "&[aria-expanded='false']": {
    width: "64px",
  },
  "@media (max-height: 600px)": {
    fontSize: "0.9rem",
  },
}));

const GroupLabel = styled(Box, {
  shouldForwardProp: prop => prop !== "expanded",
})<{ expanded?: boolean }>(({ theme }) => ({
  padding: 0,
  position: "relative",
  height: "28px",
  marginTop: theme.spacing(0.5),
  marginLeft: theme.spacing(0.5),
  "@media (max-height: 600px)": {
    height: "20px",
    marginTop: theme.spacing(0.5),
  },
}));

const LabelContent = styled(Box, {
  shouldForwardProp: prop => prop !== "expanded",
})<{ expanded?: boolean }>(({ expanded }) => ({
  position: "absolute",
  left: "12px",
  top: "50%",
  transform: "translateY(-50%)",
  opacity: expanded ? 1 : 0,
  transition: "opacity 150ms ease-in-out, visibility 0ms linear",
  visibility: expanded ? "visible" : "hidden",
  pointerEvents: expanded ? "auto" : "none",
}));

const NavItemGroup = styled(Box)(({ theme }) => ({
  "& > *": {
    marginBottom: theme.spacing(0.5),
  },
  marginBottom: theme.spacing(0.5),
  "@media (max-height: 600px)": {
    "& > *": {
      marginBottom: theme.spacing(0.3),
    },
    marginBottom: theme.spacing(0.3),
  },
}));

const NavItemWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== "expanded" && prop !== "itemIndex",
})<{ expanded?: boolean; itemIndex: number }>(({ expanded, itemIndex }) => ({
  opacity: expanded ? 1 : 0.95,
  transition: "opacity 200ms ease-out",
  transitionDelay: expanded ? `${120 + itemIndex * 25}ms` : "0ms",
  "@media (max-height: 600px)": {
    transform: "scale(0.95)",
  },
}));

const SidebarPlaceholder = styled(Box)({
  minWidth: "64px",
  width: "64px",
  flexShrink: 0,
});

const AvatarContainer = styled(Box, {
  shouldForwardProp: prop => prop !== "expanded",
})<{ expanded?: boolean }>(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  position: "relative",
  height: "64px",
  marginBottom: "0px",
}));

const OrgNameLabel = styled(Typography, {
  shouldForwardProp: prop => prop !== "expanded",
})<{ expanded?: boolean }>(({ expanded }) => ({
  position: "absolute",
  left: "56px",
  opacity: expanded ? 1 : 0,
  visibility: expanded ? "visible" : "hidden",
  transition: "opacity 200ms ease-out, visibility 200ms ease-out",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontWeight: 700,
  maxWidth: "120px",
  fontSize: "14px",
}));

const EnhancedNavItem = ({ item, expanded, itemIndex }) => {
  return (
    <NavItemWrapper expanded={expanded} itemIndex={itemIndex}>
      <NavItem item={item} expanded={expanded} />
    </NavItemWrapper>
  );
};

export const Sidebar: React.FC<SidebarProps> = ({
  items,
  expanded = false,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { user } = useAuth0();

  const organizationName = user?.[AUTH0_ORG_CLAIM_URL] || "";
  const orgFirstLetter = organizationName ? organizationName.charAt(0).toUpperCase() : "?";

  const logoutItem = items.find(item => item.id === "logout");
  const navigationItems = items.filter(item => item.id !== "logout");

  const groupOrder = [SIDEBAR_GROUPS.PROCESSES, SIDEBAR_GROUPS.DATA_FLOW, SIDEBAR_GROUPS.MORE];

  const groupedItems = navigationItems.reduce(
    (acc, item) => {
      if (item.visible === false) return acc;

      const groupName = item.group || "Other";
      if (!acc[groupName]) {
        acc[groupName] = [];
      }
      acc[groupName].push(item);

      return acc;
    },
    {} as Record<string, typeof items>
  );

  const getGroupDisplayName = (groupKey: string) => {
    switch (groupKey) {
      case SIDEBAR_GROUPS.PROCESSES:
        return "Processes";
      case SIDEBAR_GROUPS.DATA_FLOW:
        return "Data Flow";
      case SIDEBAR_GROUPS.MORE:
        return "More";
      default:
        return capitalize(groupKey);
    }
  };

  return (
    <>
      <SidebarPlaceholder />
      <SidebarContainer
        expanded={expanded}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        aria-expanded={expanded}
        role="navigation"
      >
        <AvatarContainer expanded={expanded}>
          <Avatar
            sx={{
              width: 28,
              height: 28,
              borderRadius: "4px",
              bgcolor: "#B4593D",
              fontWeight: "bold",
              fontSize: 14,
              position: "absolute",
              left: "16px",
            }}
          >
            {capitalize(orgFirstLetter)}
          </Avatar>
          <OrgNameLabel expanded={expanded} variant="body2" data-testid="org-name">
            {capitalize(organizationName)}
          </OrgNameLabel>
        </AvatarContainer>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "calc(100% - 64px)",
            justifyContent: "space-between",
            overflow: "hidden",
            overflowX: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              overflowX: "hidden",
              flexGrow: 1,
              minHeight: 0,
            }}
          >
            {groupOrder.map(groupName => {
              const groupItems = groupedItems[groupName];
              if (!groupItems || groupItems.length === 0) return null;

              return (
                <React.Fragment key={groupName}>
                  <GroupLabel expanded={expanded}>
                    <LabelContent expanded={expanded}>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{
                          fontWeight: 600,
                          fontSize: "0.7rem",
                          textTransform: "uppercase",
                          letterSpacing: "0.1em",
                        }}
                      >
                        {getGroupDisplayName(groupName)}
                      </Typography>
                    </LabelContent>
                  </GroupLabel>
                  <NavItemGroup>
                    {groupItems.map((item, index) => (
                      <EnhancedNavItem
                        key={item.id}
                        item={item}
                        expanded={expanded}
                        itemIndex={index}
                      />
                    ))}
                  </NavItemGroup>
                </React.Fragment>
              );
            })}

            {groupedItems["Other"] && (
              <React.Fragment>
                <GroupLabel expanded={expanded}>
                  <LabelContent expanded={expanded}>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{
                        fontWeight: 600,
                        fontSize: "0.7rem",
                        textTransform: "uppercase",
                        letterSpacing: "0.1em",
                        display: "block",
                        lineHeight: 1,
                      }}
                    >
                      Other
                    </Typography>
                  </LabelContent>
                </GroupLabel>
                <NavItemGroup>
                  {groupedItems["Other"].map((item, index) => (
                    <EnhancedNavItem
                      key={item.id}
                      item={item}
                      expanded={expanded}
                      itemIndex={index} // Pass index for staggered animation
                    />
                  ))}
                </NavItemGroup>
              </React.Fragment>
            )}
          </Box>

          {logoutItem && (
            <Box sx={{ mt: "auto" }}>
              <EnhancedNavItem item={logoutItem} expanded={expanded} itemIndex={0} />
            </Box>
          )}
        </Box>
      </SidebarContainer>
    </>
  );
};
