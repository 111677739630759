import { RouteObject } from "react-router-dom";

import { lazyWithReload } from "@/core/lazyWithRetry";
import { ROUTES } from "@/routes/constants";

const SettingsView = lazyWithReload(() => import("./SettingsGateway"));

// Note: No top-level ProtectedRoute as per original App.tsx structure for these
export const settingsRoutes: RouteObject = {
  path: ROUTES.SETTINGS,
  element: <SettingsView />,
};
