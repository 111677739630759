/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GoogleSheetsExportOptionsDto } from '../models/GoogleSheetsExportOptionsDto';
import type { NodeOptionDto } from '../models/NodeOptionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GoogleSheetsAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param templateFolderId
   * @returns GoogleSheetsExportOptionsDto Success
   * @throws ApiError
   */
  public getConfigOptions(
    templateFolderId?: string,
  ): CancelablePromise<GoogleSheetsExportOptionsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/workflows/blocks/google-sheets-export',
      query: {
        'templateFolderId': templateFolderId,
      },
    });
  }

  /**
   * @returns boolean Success
   * @throws ApiError
   */
  public requestAccess(): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/workflows/blocks/google-sheets-export/request-access',
    });
  }

  /**
   * @param sheetId
   * @returns string Success
   * @throws ApiError
   */
  public getTabs(
    sheetId: string,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/workflows/blocks/google-sheets-export/tabs/{sheetId}',
      path: {
        'sheetId': sheetId,
      },
    });
  }

  /**
   * @param sheetId
   * @returns NodeOptionDto Success
   * @throws ApiError
   */
  public getColumns(
    sheetId: string,
  ): CancelablePromise<Array<NodeOptionDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/workflows/blocks/google-sheets-export/columns/{sheetId}',
      path: {
        'sheetId': sheetId,
      },
    });
  }

  /**
   * @deprecated
   * @returns GoogleSheetsExportOptionsDto Success
   * @throws ApiError
   */
  public getConfigOptions1(): CancelablePromise<GoogleSheetsExportOptionsDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/integrations/googlesheets',
    });
  }

  /**
   * @deprecated
   * @returns boolean Success
   * @throws ApiError
   */
  public requestAccess1(): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/integrations/googlesheets/request-access',
    });
  }

  /**
   * @deprecated
   * @param sheetId
   * @returns string Success
   * @throws ApiError
   */
  public getTabs1(
    sheetId: string,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/integrations/googlesheets/tabs/{sheetId}',
      path: {
        'sheetId': sheetId,
      },
    });
  }

}
