import { useAuth0 } from "@auth0/auth0-react";
import { LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

import { agentApiClient } from "@/core/agentApi";
import { boApiClient } from "@/core/api";
import { accessTokenRequest } from "@/core/auth";

interface AuthInterceptorProps {
  children: React.ReactNode;
}

// Store the token globally so it can be used by the fetch interceptor
let globalAuthToken: string | null = null;

/**
 * A higher-order component that ensures Auth0 is fully initialized and authenticated
 * before rendering any children. This helps prevent 401 errors when directly
 * navigating to a URL by ensuring auth tokens are loaded properly.
 */
export const AuthInterceptor: React.FC<AuthInterceptorProps> = ({ children }) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [isInitialized, setIsInitialized] = useState(false);
  const [initAttempts, setInitAttempts] = useState(0);
  const MAX_ATTEMPTS = 5;

  // Set up global fetch interceptor when the component first mounts
  useEffect(() => {
    // Save the original fetch function
    const originalFetch = window.fetch;

    // Replace with our version that adds auth headers when available
    window.fetch = async (input, init) => {
      // Only add auth headers if we have a token and the request is to our API
      const url =
        typeof input === "string" ? input : input instanceof URL ? input.toString() : input.url;

      if (globalAuthToken && url.includes("api")) {
        // Create headers object if it doesn't exist
        const headers = init?.headers ? new Headers(init.headers) : new Headers();

        // Add authorization header if it's not already set
        if (!headers.has("Authorization")) {
          headers.set("Authorization", `Bearer ${globalAuthToken}`);
        }

        // Create new init object with updated headers
        const newInit = {
          ...init,
          headers,
        };

        // Call original fetch with updated init
        return originalFetch(input, newInit);
      }

      // If no token or not an API request, just pass through
      return originalFetch(input, init);
    };

    // Restore original fetch on cleanup
    return () => {
      window.fetch = originalFetch;
    };
  }, []);

  useEffect(() => {
    // Skip initialization if not authenticated or still loading
    if (isLoading || !isAuthenticated) {
      // If not authenticated, mark as initialized to allow login flow to proceed
      if (!isLoading && !isAuthenticated) {
        setIsInitialized(true);
      }
      return;
    }

    let isMounted = true;

    const initializeAuth = async () => {
      try {
        console.log("AuthInterceptor: Starting token initialization...");

        // First, add a small delay to ensure Auth0 context is fully ready
        await new Promise(resolve => setTimeout(resolve, 100 * (initAttempts + 1)));

        // Get the token and validate it exists
        const token = await getAccessTokenSilently(accessTokenRequest);

        if (!token) {
          throw new Error("No token received from Auth0");
        }

        // Store token globally for the fetch interceptor
        globalAuthToken = token;

        // Initialize API clients globally with this token
        boApiClient.request.config.TOKEN = token;
        agentApiClient.request.config.TOKEN = token;

        console.log("AuthInterceptor: Token successfully initialized");

        if (isMounted) {
          setIsInitialized(true);
        }
      } catch (error) {
        console.error("AuthInterceptor: Error initializing token:", error);

        // Retry logic with backoff
        if (isMounted && initAttempts < MAX_ATTEMPTS) {
          const nextAttempt = initAttempts + 1;
          console.log(
            `AuthInterceptor: Retrying initialization (${nextAttempt}/${MAX_ATTEMPTS})...`
          );
          setInitAttempts(nextAttempt);
        } else if (isMounted) {
          // After max attempts, let the app load anyway to avoid blocking forever
          console.warn("AuthInterceptor: Max initialization attempts reached, proceeding anyway");
          setIsInitialized(true);
        }
      }
    };

    if (!isInitialized) {
      initializeAuth();
    }

    return () => {
      isMounted = false;
    };
  }, [isAuthenticated, isLoading, getAccessTokenSilently, isInitialized, initAttempts]);

  // Set up token refresh to ensure token never expires
  useEffect(() => {
    if (!isAuthenticated || !isInitialized) {
      return;
    }

    let refreshInterval: number | null = null;

    const refreshToken = async () => {
      try {
        console.log("AuthInterceptor: Refreshing auth token...");
        const newToken = await getAccessTokenSilently({
          ...accessTokenRequest,
          cacheMode: "no-cache",
        });

        if (newToken) {
          globalAuthToken = newToken;
          boApiClient.request.config.TOKEN = newToken;
          agentApiClient.request.config.TOKEN = newToken;
          console.log("AuthInterceptor: Token refreshed successfully");
        }
      } catch (error) {
        console.error("AuthInterceptor: Error refreshing token:", error);
      }
    };

    // Refresh token every 15 minutes to ensure it never expires
    // Auth0 tokens typically last 24 hours, but we refresh more often to be safe
    refreshInterval = window.setInterval(refreshToken, 15 * 60 * 1000);

    return () => {
      if (refreshInterval !== null) {
        clearInterval(refreshInterval);
      }
    };
  }, [isAuthenticated, isInitialized, getAccessTokenSilently]);

  // Show loading indicator when not initialized but authenticated
  if (isAuthenticated && !isInitialized) {
    return (
      <div style={{ width: "100%", padding: "20px" }}>
        <LinearProgress />
        <div style={{ textAlign: "center", marginTop: "10px", fontSize: "14px", color: "#666" }}>
          Initializing application...
        </div>
      </div>
    );
  }

  // Otherwise, render children
  return <>{children}</>;
};

export default AuthInterceptor;
