import { User } from "@auth0/auth0-react";
import Hotjar from "@hotjar/browser";
import type { default as MixPanelType } from "mixpanel-browser";

import { AUTH0_ORG_CLAIM_URL } from "@/utils/auth/config";
import { devMixpanelConfig, prodMixpanelConfig } from "@/utils/mixpanel/configs";

/**
 * User data interface for analytics services
 */
export interface UserData {
  name?: string;
  email?: string;
  sub?: string;

  [key: string]: any;
}

/**
 * Initialize Mixpanel with the appropriate configuration based on environment
 * @param mixpanel - Mixpanel instance
 * @param isAuthenticated - Whether the user is authenticated
 */
export const initializeMixpanel = (
  mixpanel: typeof MixPanelType,
  isAuthenticated: boolean
): void => {
  if (!isAuthenticated || !mixpanel) return;

  if (import.meta.env.PROD) {
    mixpanel.init(prodMixpanelConfig.token, {
      ...prodMixpanelConfig.attributes,
    });
  } else {
    mixpanel.init(devMixpanelConfig.token, {
      ...devMixpanelConfig.attributes,
    });
    mixpanel.disable();
  }
};

/**
 * Set user identity in Mixpanel for tracking
 * @param mixpanel - Mixpanel instance
 * @param userData - User data for identification
 */
export const setMixpanelIdentity = (
  mixpanel: typeof MixPanelType,
  userData: User | UserData
): void => {
  if (!mixpanel || !userData) return;

  mixpanel.identify(userData.name);
  mixpanel.register({
    name: userData.name,
    $email: userData.email,
    org: userData[AUTH0_ORG_CLAIM_URL],
    sub: userData.sub,
  });
};

/**
 * Initialize Hotjar for user tracking and feedback
 */
export const initializeHotjar = (): void => {
  if (!import.meta.env.PROD) return;

  const siteId = 3667881;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
};

/**
 * Identify user in Hotjar for session tracking
 * @param userData - User data for identification
 */
export const identifyHotjarUser = (userData: User | UserData): void => {
  if (!import.meta.env.PROD || !userData) return;

  Hotjar.identify(userData.sub, {
    email: userData.email,
    org: userData[AUTH0_ORG_CLAIM_URL],
  });
};
