import { useAuth0 } from "@auth0/auth0-react";
import { Box, Paper, Typography } from "@mui/material";
import React from "react";

const NoOrganizations = () => {
  const { logout } = useAuth0();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.6)",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: 2,
          width: "fit-content",
          gap: 2,
        }}
      >
        <Box sx={{ width: 320 }}>
          <Typography
            variant="h5"
            fontFamily="ABCWhyte"
            fontWeight={700}
            fontSize={24}
            sx={{ mb: 2 }}
          >
            Welcome!
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
            align="left"
            fontSize={14}
            sx={{ mb: 2 }}
          >
            It appears you haven't received invitations to any existing organizations. If you were
            expecting an invitation, double-check if you signed up with the correct email.
          </Typography>
          <Typography
            onClick={() => logout({ returnTo: window.location.origin + "/login" })}
            variant="subtitle2"
            align="left"
            fontFamily="ABCWhyte"
            fontSize={14}
            sx={{
              color: "#1D42C9",
              mt: 5,
              cursor: "pointer",
            }}
          >
            Sign up with a another email
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default NoOrganizations;
