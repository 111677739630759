/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FileDto } from '../models/FileDto';
import type { LatestFileUploadByTransactionSourceDto } from '../models/LatestFileUploadByTransactionSourceDto';
import type { RelevantFilesDto } from '../models/RelevantFilesDto';
import type { UpdateFileDto } from '../models/UpdateFileDto';
import type { UploadSummaryDto } from '../models/UploadSummaryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FilesAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @param periods
   * @param transactionSourceId
   * @returns FileDto Success
   * @throws ApiError
   */
  public getFiles(
    periods?: string,
    transactionSourceId?: string,
  ): CancelablePromise<Array<FileDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/files',
      query: {
        'periods': periods,
        'transactionSourceId': transactionSourceId,
      },
    });
  }

  /**
   * @param formData
   * @returns FileDto Success
   * @throws ApiError
   */
  public uploadFiles(
    formData?: {
      file?: Blob;
    },
  ): CancelablePromise<FileDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/files',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @param periods
   * @param transactionSourceId
   * @returns UploadSummaryDto Success
   * @throws ApiError
   */
  public getUploadOverview(
    periods?: string,
    transactionSourceId?: string,
  ): CancelablePromise<UploadSummaryDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/files/overview',
      query: {
        'periods': periods,
        'transactionSourceId': transactionSourceId,
      },
    });
  }

  /**
   * @param fileUuid
   * @returns FileDto Success
   * @throws ApiError
   */
  public getFile(
    fileUuid: string,
  ): CancelablePromise<FileDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/files/file/{file_uuid}',
      path: {
        'file_uuid': fileUuid,
      },
    });
  }

  /**
   * @param fileUuid
   * @param requestBody
   * @returns FileDto Success
   * @throws ApiError
   */
  public updateFile(
    fileUuid: string,
    requestBody?: UpdateFileDto,
  ): CancelablePromise<FileDto> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/files/file/{file_uuid}',
      path: {
        'file_uuid': fileUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @param fileUuid
   * @returns FileDto Success
   * @throws ApiError
   */
  public deleteFile(
    fileUuid: string,
  ): CancelablePromise<FileDto> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/files/file/{file_uuid}',
      path: {
        'file_uuid': fileUuid,
      },
    });
  }

  /**
   * @returns string Success
   * @throws ApiError
   */
  public getSummary(): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/files/summary',
    });
  }

  /**
   * @returns RelevantFilesDto Success
   * @throws ApiError
   */
  public getRelevantFiles(): CancelablePromise<RelevantFilesDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/files/relevant',
    });
  }

  /**
   * @returns LatestFileUploadByTransactionSourceDto Success
   * @throws ApiError
   */
  public getLatestUploadedFiles(): CancelablePromise<Array<LatestFileUploadByTransactionSourceDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/files/latestUploadedFiles',
    });
  }

  /**
   * @param fileUuid
   * @returns string Success
   * @throws ApiError
   */
  public downloadFile(
    fileUuid: string,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/files/download/{file_uuid}',
      path: {
        'file_uuid': fileUuid,
      },
    });
  }

  /**
   * @param formData
   * @returns FileDto Success
   * @throws ApiError
   */
  public uploadIntegrationFiles(
    formData?: {
      file?: Blob;
    },
  ): CancelablePromise<FileDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/files/integration',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

}
