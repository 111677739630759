import { RouteObject } from "react-router-dom";

import { lazyWithReload } from "@/core/lazyWithRetry";
import { ROUTE_FEATURE_FLAGS, ROUTES } from "@/routes/constants";
import { ProtectedRoute } from "@components/Global/ProtectedRoute";

const LookerDashboard = lazyWithReload(() => import("./components/LookerDashboard"));

export const lookerRoutes: RouteObject[] = [
  {
    element: <ProtectedRoute featureFlag={ROUTE_FEATURE_FLAGS[ROUTES.DASHBOARD]} />,
    children: [{ path: ROUTES.DASHBOARD, element: <LookerDashboard /> }],
  },
];
