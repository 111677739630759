/* eslint-disable */
import moment from "moment";
import { v4 as uuid } from "uuid";

import { CalculationResultParamsDto } from "../spec/bo";

export const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || "";

export const truncateString = (string: string, length: number) => {
  if (string.length > length) {
    return string.slice(0, length) + "...";
  } else {
    return string;
  }
};

export const formatNumber = (number: number | null): string => {
  if (!number) return "0";
  return number.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

export const formatNumberWithoutCommas = (number: number | null): string => {
  if (!number) return "0";
  return number.toLocaleString("en-US", {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
};

export const formatNumberWithCommas = (number: number | null): string => {
  if (number === 0) return "0";
  if (!number) return " ";
  return number.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

export const truncateMiddleOfString = (
  str: string,
  firstCharCount = str.length,
  endCharCount = 0,
  dotCount = 3
) => {
  if (str.length <= firstCharCount + endCharCount) {
    return str; // No truncation needed
  }

  const firstPortion = str.slice(0, firstCharCount);
  const endPortion = str.slice(-endCharCount);
  const dots = ".".repeat(dotCount);

  return `${firstPortion}${dots}${endPortion}`;
};

export const wait = (time: number) => new Promise(resolve => setTimeout(resolve, time));

export const getQueryParametersFromModelResultTypeData = (data): CalculationResultParamsDto => {
  if (data.resultsType === "invoice") {
    return {
      resultType: "for_invoices",
      invoiceUuid: data.invoiceUuid,
    };
  }

  if (data.resultsType === "custom" && data.fromDate && data.toDate) {
    return {
      resultType: "for_period",
      periodStart: data.fromDate,
      periodEnd: data.toDate,
    };
  }

  return {
    resultType: "all",
    resultGrouping: "months",
  };
};

const emptyRow = () => ({
  id: uuid(),
  type: "empty",
  start_date: null,
  end_date: null,
  cost_currency: null,
  cost_value: null,
  description: null,
  count: null,
});

export const formatIceResults = (results, paramData) => {
  if (!results || results.length === 0) {
    return [];
  }

  const resultRows =
    results?.periods?.reduce((formattedResults, period) => {
      if (!period?.entries || period.entries.length === 0) {
        const emptyPeriod = {
          id: uuid(),
          type: "total_period",
          start_date: moment(period.start_date).format("MMM D, YYYY"),
          end_date: moment(period.end_date).format("MMM D, YYYY"),
          cost_value: 0,
          description: "Period total",
          count: 0,
        };

        return [...formattedResults, emptyPeriod, emptyRow()];
      }

      const newEntries = period.entries
        .map(({ uuid, amount, currency, count, description, transaction_type }) => ({
          id: uuid + period.start_date,
          type: "entry",
          start_date: moment(period.start_date).format("MMM D, YYYY"),
          end_date: moment(period.end_date).format("MMM D, YYYY"),
          cost_currency: currency,
          cost_value: amount,
          description,
          count,
          transaction_type,
        }))
        .sort((a, _) => {
          if (a.transaction_type === "info_element") return 1;
          return -1;
        });

      const periodTotals =
        period.period_total?.map(periodTotal => ({
          id: uuid(),
          type: "total_period",
          start_date: moment(period.start_date).format("MMM D, YYYY"),
          end_date: moment(period.end_date).format("MMM D, YYYY"),
          cost_currency: periodTotal.currency,
          cost_value: periodTotal.sum,
          description: "Period total",
          count: periodTotal.count,
        })) || [];

      return [...formattedResults, ...newEntries, ...periodTotals, emptyRow()];
    }, []) || [];

  if (!paramData.invoiceUuid) {
    const queryTotalRows =
      results.query_total?.map(queryTotal => ({
        id: uuid(),
        type: "total_query",
        start_date: moment(results.start_date).format("MMM D, YYYY"),
        end_date: moment(results.end_date).format("MMM D, YYYY"),
        cost_currency: queryTotal.currency,
        cost_value: queryTotal.sum,
        description: "Query total",
        count: queryTotal.count,
      })) || [];

    return [...resultRows, ...queryTotalRows];
  }

  return [...resultRows];
};
