import { useAuth0 } from "@auth0/auth0-react";
import { LinearProgress } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

type ProtectedRouteProps = {
  featureFlag?: string;
  redirectPath?: string;
};

/**
 * A wrapper component that protects routes based on LaunchDarkly feature flags
 * If the feature flag is not enabled, redirects to the specified path
 * Waits for LaunchDarkly to initialize before making routing decisions
 * Also ensures Auth0 is fully loaded before rendering protected routes
 *
 * @param featureFlag - The LaunchDarkly feature flag to check
 * @param redirectPath - The path to redirect to if the feature flag is not enabled (defaults to /app/matching)
 */
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  featureFlag,
  redirectPath = "/app/matching",
}) => {
  // Get all feature flags from LaunchDarkly
  const featureFlags = useFlags();
  const { isAuthenticated, isLoading: isAuthLoading, getAccessTokenSilently } = useAuth0();
  const [flagsReady, setFlagsReady] = useState(false);
  const [authReady, setAuthReady] = useState(false);

  // We need to wait a moment to ensure all flags are properly loaded
  useEffect(() => {
    // If there are flags in the object or no feature flag is required, consider it ready
    if (Object.keys(featureFlags).length > 0 || !featureFlag) {
      setFlagsReady(true);
    } else {
      // Set a short timeout to wait for flags to load
      const timer = setTimeout(() => {
        setFlagsReady(true);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [featureFlags, featureFlag]);

  // Ensure auth token is loaded
  useEffect(() => {
    if (!isAuthenticated || isAuthLoading) {
      return;
    }

    let isMounted = true;

    const loadAuth = async () => {
      try {
        // Try to get the token to ensure auth is properly initialized
        await getAccessTokenSilently();
        if (isMounted) {
          setAuthReady(true);
        }
      } catch (error) {
        console.error("Error initializing auth in ProtectedRoute:", error);
        // Still mark as ready to prevent infinite loading
        if (isMounted) {
          setAuthReady(true);
        }
      }
    };

    loadAuth();

    return () => {
      isMounted = false;
    };
  }, [isAuthenticated, isAuthLoading, getAccessTokenSilently]);

  // If flags or auth are not ready, show loading indicator
  if (!flagsReady || !authReady) {
    return <LinearProgress color="primary" />;
  }

  // If no feature flag is specified, allow access
  if (!featureFlag) {
    return <Outlet />;
  }

  // Check if the feature flag is enabled
  const isFeatureEnabled = featureFlags[featureFlag];

  // If the feature is not enabled, redirect to the specified path
  if (!isFeatureEnabled) {
    return <Navigate to={redirectPath} replace />;
  }

  // If the feature is enabled, render the route
  return <Outlet />;
};
