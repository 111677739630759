import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useContext } from "react";

import MixpanelContext from "../../../context/MixpanelContext";
import { filterNavItems, getDefaultNavItems } from "../api/navItems";

/**
 * Hook to provide navigation items filtered by feature flags from LaunchDarkly
 * Uses React Query to cache the result
 */
export const useNavItems = () => {
  // Get feature flags from LaunchDarkly
  const featureFlags = useFlags();

  // Get mixpanel for tracking
  const { mixpanel } = useContext(MixpanelContext);

  // Get auth0 logout function
  const { logout } = useAuth0();

  // Query to cache the filtered navigation items
  const { data } = useQuery({
    queryKey: ["Sidebar", "navItems", featureFlags],
    queryFn: () => {
      // Get default items
      const defaultItems = getDefaultNavItems();

      // Filter items based on feature flags
      const filteredItems = filterNavItems(defaultItems, featureFlags as Record<string, boolean>);

      // Add tracking to each item's onClick
      const navItems = filteredItems.map(item => ({
        ...item,
        onClick: () => {
          mixpanel?.track?.("Navigation", {
            "Navigation Item": item.path,
            "Navigation Type": "Sidebar",
            "Navigation Action": "Click",
            "Navigation Route": item.title,
          });
        },
      }));

      // Add logout item
      const logoutItem = {
        id: "logout",
        title: "Log out",
        icon: "LogoutOutlinedIcon",
        path: "#", // Placeholder, not used for logout
        onClick: () => {
          mixpanel?.track?.("Logout", {});
          mixpanel?.reset?.();
          logout({
            returnTo: window.location.origin + "/login",
          });
        },
        // Not part of any group, will be handled separately in the Sidebar component
      };

      return [...navItems, logoutItem];
    },
    // Items should be immediately available, don't refetch on window focus
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  return data || [];
};
