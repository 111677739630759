import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Box, styled, Typography } from "@mui/material";
import React, { isValidElement } from "react";
import { NavLink } from "react-router-dom";

import { NavItem as NavItemType } from "../types";

// Styled components
const StyledNavLink = styled(NavLink, {
  shouldForwardProp: prop => prop !== "expanded",
})<{ expanded?: boolean }>(() => ({
  display: "flex",
  position: "relative",
  margin: "8px 0",
  textDecoration: "none",
  color: "inherit",
  width: "100%",
  height: "40px",
  "&.disabled": {
    pointerEvents: "none",
    cursor: "not-allowed",
  },
}));

const StyledExternalLink = styled("a", {
  shouldForwardProp: prop => prop !== "expanded",
})<{ expanded?: boolean }>(() => ({
  display: "flex",
  position: "relative",
  margin: "8px 0",
  textDecoration: "none",
  color: "inherit",
  width: "100%",
  height: "40px",
  "&.disabled": {
    pointerEvents: "none",
    cursor: "not-allowed",
  },
}));

const NavItemContent = styled(Box, {
  shouldForwardProp: prop => prop !== "expanded",
})<{ expanded?: boolean }>(({ theme, expanded }) => ({
  position: "absolute",
  left: "12px",
  height: "40px",
  width: expanded ? "calc(100% - 24px)" : "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  transition: "background-color 100ms ease-out, width 200ms ease-out",
  borderRadius: "6px",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&.active": {
    backgroundColor: "#EEF1FB",
    color: "#142E90",
    "& img": {
      filter:
        "brightness(0) saturate(100%) invert(14%) sepia(76%) saturate(3962%) hue-rotate(225deg) brightness(94%) contrast(96%)",
    },
  },
  "&.disabled": {
    opacity: 0.4,
    color: theme.palette.action.disabled,
    "& img": {
      filter: "opacity(0.4)",
    },
  },
}));

const IconWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== "expanded",
})<{ expanded?: boolean }>(() => ({
  height: "40px",
  width: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,
  color: "rgba(0,0,0,0.8)",
  "&.active, .active &": {
    color: "#142E90",
  },
  "& svg": {
    width: "18px",
    height: "18px",
    fontSize: "18px",
  },
}));

// Custom styled component for SVG images
const StyledImg = styled("img")({
  width: "18px",
  height: "18px",
  filter: "opacity(0.8)",
});

const LabelWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== "expanded",
})<{ expanded?: boolean }>(({ expanded }) => ({
  height: "100%",
  opacity: expanded ? 1 : 0,
  visibility: expanded ? "visible" : "hidden",
  transition: "opacity 200ms ease-out, visibility 200ms ease-out",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "flex",
  alignItems: "center",
  paddingRight: "12px",
  paddingLeft: "2px",
}));

interface NavItemProps {
  item: NavItemType;
  expanded?: boolean;
}

export const NavItem: React.FC<NavItemProps> = ({ item, expanded = false }) => {
  // Don't render if not visible
  if (item.visible === false) {
    return null;
  }

  const renderIcon = () => {
    if (isValidElement(item.icon)) {
      // Let the CSS in IconWrapper handle the sizing
      return item.icon;
    } else if (typeof item.icon === "string") {
      if (item.icon === "LogoutOutlinedIcon") {
        return <LogoutOutlinedIcon />;
      }
      return <StyledImg src={item.icon} alt={item.title} />;
    } else {
      return <StyledImg src={item.icon as string} alt={item.title} />;
    }
  };

  const content = (isActive = false) => (
    <NavItemContent
      className={`${isActive ? "active" : ""} ${item.disabled ? "disabled" : ""}`}
      expanded={expanded}
      data-testid={`nav-item-content-${item.id}`}
    >
      <IconWrapper expanded={expanded}>{renderIcon()}</IconWrapper>
      <LabelWrapper expanded={expanded}>
        <Typography variant="body2" fontSize="12px" fontWeight="normal" noWrap>
          {item.title}
        </Typography>
      </LabelWrapper>
    </NavItemContent>
  );

  if (item.isExternal) {
    return (
      <StyledExternalLink
        href={item.path}
        target="_blank"
        rel="noopener noreferrer"
        onClick={item.onClick}
        className={item.disabled ? "disabled" : ""}
        expanded={expanded}
      >
        {content()}
      </StyledExternalLink>
    );
  }

  // For disabled items, use a div with the same styling instead of a NavLink
  if (item.disabled) {
    return (
      <Box
        sx={{
          position: "relative",
          margin: "8px 0",
          width: "100%",
          height: "40px",
        }}
      >
        {content()}
      </Box>
    );
  }

  if (item.id === "logout") {
    return (
      <Box
        sx={{
          position: "relative",
          margin: "8px 0",
          width: "100%",
          height: "40px",
          cursor: "pointer",
        }}
        onClick={item.onClick}
      >
        {content()}
      </Box>
    );
  }

  return (
    <StyledNavLink
      to={item.path}
      onClick={item.onClick}
      className={({ isActive }) => (isActive ? "active" : "")}
      expanded={expanded}
    >
      {({ isActive }) => content(isActive)}
    </StyledNavLink>
  );
};
