import React, { memo } from "react";

import { useNavItems, useSidebar } from "@/features/Sidebar";

import { Sidebar } from "./Sidebar";

/**
 * Application Sidebar component
 * Uses the Sidebar hooks to get navigation items and manage state
 */
const AppSidebarComponent: React.FC = () => {
  const { hovered, handleMouseEnter, handleMouseLeave } = useSidebar();
  const navItems = useNavItems();

  return (
    <Sidebar
      items={navItems}
      expanded={hovered}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  );
};

export const AppSidebar = memo(AppSidebarComponent);
