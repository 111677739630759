/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TransactionSourceDto } from '../models/TransactionSourceDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TransactionSourceAPI {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns TransactionSourceDto Success
   * @throws ApiError
   */
  public getTransactionSources(): CancelablePromise<Array<TransactionSourceDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/transaction-source',
    });
  }

}
