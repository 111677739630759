import { HelpOutlineOutlined, UploadFileOutlined } from "@mui/icons-material";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import React from "react";

import workflowIcon from "../../../assets/icons/workflowIcon.svg";
import { NavItem } from "../types";

export const SIDEBAR_GROUPS = {
  PROCESSES: "Processes",
  DATA_FLOW: "Data flow",
  MORE: "More",
};

/**
 * Default navigation items for the sidebar
 * These can be filtered based on feature flags from LaunchDarkly
 */
export const getDefaultNavItems = (): NavItem[] => [
  {
    id: "workflows",
    title: "Workflows",
    icon: workflowIcon,
    path: "/app/tasks",
    featureFlag: "gen1460Workflows",
    group: SIDEBAR_GROUPS.PROCESSES,
  },
  {
    id: "matching",
    title: "Payments reconciliation",
    icon: <AddToPhotosOutlinedIcon />,
    path: "/app/matching",
    featureFlag: "matchingTool",
    group: SIDEBAR_GROUPS.PROCESSES,
  },
  {
    id: "dashboard",
    title: "Dashboards",
    icon: <InsertChartOutlinedIcon />,
    path: "/app/dashboard",
    featureFlag: "lookerDashboard",
    group: SIDEBAR_GROUPS.PROCESSES,
  },
  {
    id: "settlements",
    title: "Settlements",
    icon: <ArticleOutlinedIcon />,
    path: "/app/settlement",
    featureFlag: "settlements",
    group: SIDEBAR_GROUPS.PROCESSES,
  },
  {
    id: "web-agents",
    title: "Web agents",
    icon: <SupportAgentOutlinedIcon />,
    path: "/app/agents",
    group: SIDEBAR_GROUPS.PROCESSES,
  },
  {
    id: "fee-estimation",
    title: "Fee estimation",
    icon: <GridViewOutlinedIcon />,
    path: "/app/projects",
    featureFlag: "feeEstimation",
    group: SIDEBAR_GROUPS.PROCESSES,
  },
  {
    id: "integrations",
    title: "Integrations",
    icon: <HubOutlinedIcon />,
    path: "/app/integrations",
    group: SIDEBAR_GROUPS.DATA_FLOW,
  },
  {
    id: "settings",
    title: "Settings",
    icon: <SettingsOutlinedIcon />,
    path: "/app/settings",
    group: SIDEBAR_GROUPS.DATA_FLOW,
  },
  {
    id: "file-upload",
    title: "File upload",
    icon: <UploadFileOutlined />,
    path: "/app/upload",
    featureFlag: "fileUpload",
    group: SIDEBAR_GROUPS.DATA_FLOW,
  },
  {
    id: "knowledge-base",
    title: "Knowledge base",
    icon: <HelpOutlineOutlined />,
    path: "https://reiterate.notion.site/knowbase?v=197314fed29880888c45000cda2c288b",
    isExternal: true,
    group: SIDEBAR_GROUPS.MORE,
  },
];

/**
 * Filters navigation items based on feature flags from LaunchDarkly
 * For most items with disabled feature flags, marks them as disabled
 * For settlements and feeEstimation, completely removes them when their feature flags are disabled
 */
export const filterNavItems = (
  items: NavItem[],
  featureFlags: Record<string, boolean>
): NavItem[] => {
  // Filter out items that should be completely hidden when disabled
  const filteredItems = items.filter(item => {
    // Hide settlements and fee-estimation entirely when disabled
    return !(
      (item.id === "settlements" || item.id === "fee-estimation") &&
      item.featureFlag &&
      !featureFlags[item.featureFlag]
    );
  });

  return filteredItems.map(item => {
    if (item.featureFlag && !featureFlags[item.featureFlag]) {
      return {
        ...item,
        disabled: true,
      };
    }
    return item;
  });
};
