import { Box } from "@mui/material";
import { ReactNode } from "react";

import { AppSidebar } from "@/features/Sidebar";

const AppContainer = ({ children }: { children?: ReactNode }) => {
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <AppSidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "auto",
          width: "calc(100% - 64px)",
          boxSizing: "border-box",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AppContainer;
