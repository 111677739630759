import { RouteObject } from "react-router-dom";

import { lazyWithReload } from "@/core/lazyWithRetry";
import { ROUTES } from "@/routes/constants";

const IntegrationsRedirectView = lazyWithReload(() => import("./IntegrationsRedirect"));
const IntegrationsView = lazyWithReload(() => import("./IntegrationsView"));
const IntegrationsViewItem = lazyWithReload(() => import("./IntegrationsViewItem"));

// Note: No top-level ProtectedRoute as per original App.tsx structure for these
export const integrationRoutes: RouteObject[] = [
  { path: ROUTES.INTEGRATIONS.REDIRECT, element: <IntegrationsRedirectView /> },
  { path: ROUTES.INTEGRATIONS.ROOT, element: <IntegrationsView /> },
  { path: ROUTES.INTEGRATIONS.NEW(), element: <IntegrationsViewItem isNew /> },
  { path: ROUTES.INTEGRATIONS.DETAIL(), element: <IntegrationsViewItem /> },
];
